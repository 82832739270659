import Link from "next/link";

interface NavLinkLogoProps {
  className?: string;
}

const NavLinkLogo: React.FC<NavLinkLogoProps> = ({className}) => {
  return (
    <Link href="/" className={`group ${className || ""}`}>
      <div>
        <div className="logo-mozg">MOZG</div>
        <div className="logo-ii transition-colors duration-300 group-hover:text-primary">Innovative Industries</div>
      </div>
    </Link>
  );
};

export default NavLinkLogo;
