import Link from "next/link";

interface NavLinkProps {
  href: string;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
}

const NavLink = ({href, label, isActive, onClick}: NavLinkProps) => {
  return (
    <Link href={href} onClick={onClick} className={`transition-colors hover:text-primary hover:bg-transparent duration-200 px-3 py-2 text-lg`} aria-current={isActive ? "page" : undefined}>
      {label}
    </Link>
  );
};

export default NavLink;
