// app/components/AOSProvider.tsx (Client Component)
"use client";

import {useEffect} from "react";
//import "aos/dist/aos.css";

export default function AOSProvider({children}: {children: React.ReactNode}) {
  useEffect(() => {
    const isProduction = process.env.NODE_ENV === "production";
    const isBot = /bot|crawl|spider/i.test(navigator.userAgent);

    if (isProduction && !isBot) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css";
      document.head.appendChild(link);

      if (typeof window !== "undefined" && !isBot) {
        const handleUserInteraction = () => {
          import("aos").then((AOS) => {
            AOS.init({
              duration: 600,
              offset: 100,
              easing: "ease-in-out",
              once: true
            });
          });

          window.removeEventListener("scroll", handleUserInteraction);
        };

        window.addEventListener("scroll", handleUserInteraction, {once: true});
      }
    }
  }, []);

  return <>{children}</>;
}
